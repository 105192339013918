import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map((post) => {
      const {
        node: {
          fields: { slug },
          frontmatter: { title }
        }
      } = post

      return (
        <Layout>
          <li key={slug}>
            <Link to={slug}>
              <h2 className="is-size-2">{title}</h2>
            </Link>
          </li>
        </Layout>
      )
    })

    const {
      pageContext: { tag },
      data: {
        site: {
          siteMetadata: { title }
        },
        allMarkdownRemark: { totalCount }
      }
    } = this.props

    const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with “${tag}”`

    return (
      <section className="section">
        <Helmet title={`${tag} | ${title}`} />
        <div className="content container">
          <div className="columns">
            <div className="column is-10 is-offset-1" style={{ marginBottom: '6rem' }}>
              <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
              <ul className="taglist">{postLinks}</ul>
              <p>
                <Link to="/tags/">Browse all tags</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
